<template>
    <div>
        <v-app-bar
            :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
            :dark="getThemeMode.appBarColor != 'white' ? true : false"
            app
            class="px-sm text-left shadow-sm"
            height="75"
        >
            <v-app-bar-nav-icon
                v-ripple="{class: 'primary--text'}"
                @click="toggleCompactVerticalSidebarDrawer"
            />
            <v-progress-linear
                :active="getThemeMode.isLoading"
                :indeterminate="getThemeMode.isLoading"
                absolute
                bottom
                color="primary"
            />

            <v-toolbar-title>Emissis</v-toolbar-title>

            <v-spacer />


                <v-btn icon @click="logout()">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>



        </v-app-bar>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'VerticallAppBar',

    computed: {
        ...mapGetters(['getThemeMode'])
    },
    data() {
        return {
            userDrawer: false,
            notificationDrawer: false,
            searchDrawer: false
        }
    },
    methods: {
        ...mapActions(['changeCompactVerticalSidebarDrawer']),
        toggleCompactVerticalSidebarDrawer() {
            this.changeCompactVerticalSidebarDrawer()
        },
      logout() {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }

    }
}
</script>

<style scoped></style>
